<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Activatie van je e-mailadres" />
    <b-alert v-if="invalidLink || !code" show variant="warning"
      ><font-awesome-icon icon="exclamation-circle" />
      {{ $t('activation-emailadres') }}<br />

      {{ $t('account-created-page-description1') }}
      <a href="tel:092667700">09 266 77 00</a>
      {{ $t('account-created-page-description2') }}
      <a href="mailto:Info.defietsambassade@stad.gent"
        >Info.defietsambassade@stad.gent</a
      >.
    </b-alert>
    <b-row v-if="!invalidLink && code && doneLoading && !canLogin">
      <b-col xs="12" md="12">
        <h1 class="pb-3 text-center">
          {{ $t('confirmation-of-your-emailadres') }}
        </h1>
        <p>
          {{ $t('your-email-succesfully-confirmed') }}
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!invalidLink && code && doneLoading && canLogin">
      <b-col xs="12" md="12">
        <h1 class="pb-3 text-center">
          {{ $t('confirmation-of-your-emailadres') }}
        </h1>
        <h4 class="pb-3">{{ $t('thanks-for-verification-emailadres') }}!</h4>
        <p>{{ $t('emailadres-succesfully-confirmed-login') }}.</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const { confirmUser } = require('@/services/AuthorizationService')
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      invalidLink: false,
      doneLoading: false,
      canLogin: false
    }
  },
  computed: {
    code: function() {
      return this.$route.params.code || false
    }
  },
  mounted: async function() {
    const result = await confirmUser({ code: this.code })
    if (result.Message == 'InvalidModelState') {
      this.invalidLink = true
    }

    if (result.data == 'Exception_RegistrationConfirmWrongInput') {
      this.invalidLink = true
    }

    if (result.data === 'Succes_RegistrationAndUserConfirm') {
      this.canLogin = true
    }

    if (result.data === '') {
      this.invalidLink = true
    }

    this.doneLoading = true
  }
}
</script>

<style scoped lang="scss">
.page-container {
  h4 {
    font-size: 35px;
  }
  p {
    font-size: 18px;
  }
}
</style>
